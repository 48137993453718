// export const INIT_CATEGORY_DATA = 'INIT_CATEGORY_DATA';
// export const SET_CATEGORY_DATA = 'SET_CATEGORY_DATA';
// export const INIT_CATEGORY_DATA_FAILED = 'INIT_CATEGORY_DATA_FAILED';
// export const REFRESH_CATEGORY_DATA = 'REFRESH_CATEGORY_DATA';
// export const REFRESH_CATEGORY_DATA_FAILED = 'REFRESH_CATEGORY_DATA_FAILED';

// export const INIT_SUB_CATEGORY_PAGE_DATA = 'INIT_SUB_CATEGORY_PAGE_DATA';
// export const SET_SUB_CATEGORY_PAGE_DATA = 'SET_SUB_CATEGORY_PAGE_DATA';
// export const INIT_SUB_CATEGORY_PAGE_DATA_FAILED = 'INIT_SUB_CATEGORY_PAGE_DATA_FAILED';
// export const REFRESH_SUB_CATEGORY_PAGE_DATA = 'REFRESH_SUB_CATEGORY_PAGE_DATA';
// export const REFRESH_SUB_CATEGORY_PAGE_DATA_FAILED = 'REFRESH_SUB_CATEGORY_PAGE_DATA_FAILED';

// export const INIT_THREAD_DATA = 'INIT_THREAD_DATA';
// export const SET_THREAD_DATA = 'SET_THREAD_DATA';
// export const INIT_THREAD_DATA_FAILED = 'INIT_THREAD_DATA_FAILED';
// export const REFRESH_THREAD_DATA = 'REFRESH_THREAD_DATA';
// export const REFRESH_THREAD_DATA_FAILED = 'REFRESH_THREAD_DATA_FAILED';

export const INIT_USER_DATA = 'INIT_USER_DATA';
export const SET_USER_DATA = 'SET_USER_DATA';
export const INIT_USER_DATA_FAILED = 'INIT_USER_DATA_FAILED';
export const REFRESH_USER_DATA = 'REFRESH_USER_DATA';
export const REFRESH_USER_DATA_FAILED = 'REFRESH_USER_DATA_FAILED';

export const INIT_MESSAGE_DATA = 'INIT_MESSAGE_DATA';
export const SET_MESSAGE_DATA = 'SET_MESSAGE_DATA';
export const INIT_MESSAGE_DATA_FAILED = 'INIT_MESSAGE_DATA_FAILED';
export const REFRESH_MESSAGE_DATA = 'REFRESH_MESSAGE_DATA';
export const REFRESH_MESSAGE_DATA_FAILED = 'REFRESH_MESSAGE_DATA_FAILED';
export const SELECT_MESSAGE_DATA = 'SELECT_MESSAGE_DATA';
export const SELECT_MESSAGE_DATA_FAILED = 'SELECT_MESSAGE_DATA_FAILED';
export const SET_MESSAGE_POST_DATA = 'SET_MESSAGE_POST_DATA';

// export const CREATE_CATEGORY = 'CREATE_CATEGORY';
// export const CREATE_CATEGORY_FAILED = 'CREATE_CATEGORY_FAILED';

// export const CREATE_SUB_CATEGORY = 'CREATE_SUB_CATEGORY';
// export const CREATE_SUB_CATEGORY_FAILED = 'CREATE_SUB_CATEGORY_FAILED';

// export const CREATE_THREAD = 'CREATE_THREAD';
// export const CREATE_THREAD_FAILED = 'CREATE_THREAD_FAILED';

// export const CREATE_POST = 'CREATE_POST';
// export const CREATE_POST_FAILED = 'CREATE_POST_FAILED';
// export const EDIT_POST = 'EDIT_POST';
// export const EDIT_POST_FAILED = 'EDIT_POST_FAILED';
// export const DELETE_POST = 'DELETE_POST';
// export const DELETE_POST_FAILED = 'DELETE_POST_FAILED';

export const CREATE_MESSAGE = 'CREATE_MESSAGE';
export const CREATE_MESSAGE_FAILED = 'CREATE_MESSAGE_FAILED';

export const CREATE_MESSAGE_POST = 'CREATE_MESSAGE_POST';
export const CREATE_MESSAGE_POST_FAILED = 'CREATE_MESSAGE_POST_FAILED';
export const EDIT_MESSAGE_POST = 'EDIT_MESSAGE_POST';
export const EDIT_MESSAGE_POST_FAILED = 'EDIT_MESSAGE_POST_FAILED';
export const DELETE_MESSAGE_POST = 'DELETE_MESSAGE_POST';
export const DELETE_MESSAGE_POST_FAILED = 'DELETE_MESSAGE_POST_FAILED';

// export const CREATE_RATING = 'CREATE_RATING';
// export const CREATE_RATING_FAILED = 'CREATE_RATING_FAILED';
// export const DELETE_RATING = 'DELETE_RATING';
// export const DELETE_RATING_FAILED = 'DELETE_RATING_FAILED';

export const FETCH_USER_INIT = 'FETCH_USER_INIT';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';

export const LOGOUT_USER_INIT = 'LOGOUT_USER_INIT';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';

export const FETCH_USERLIST_INIT = 'FETCH_USERLIST_INIT';
export const FETCH_USERLIST_SUCCESS = 'FETCH_USERLIST_SUCCESS';
export const FETCH_USERLIST_FAILED = 'FETCH_USERLIST_FAILED';

export const REMOVE_MESSAGE_MEMBER = 'REMOVE_MESSAGE_MEMBER';
export const REMOVE_MESSAGE_MEMBER_FAILED = 'REMOVE_MESSAGE_MEMBER_FAILED';

export const SET_NO_REFRESH_FLAG = 'SET_NO_REFRESH_FLAG';
export const SET_MESSAGE_SIDEBAR_STATE = 'SET_MESSAGE_SIDEBAR_STATE';

export const INCOMING_PONG_PAYLOAD = 'INCOMING_PONG_PAYLOAD';

export const WAIT_ON_EXITING_PAGE = 'WAIT_ON_EXITING_PAGE';
export const HIDE_PAGE = 'HIDE_PAGE'; 

export const LOCATION_CHANGE = 'LOCATION_CHANGE';